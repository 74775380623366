












































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  DashboardChartModel,
  DashboardChartType,
} from "@/dashboard/models/DashboardChartModel";
import DashboardPieChartMock from "@/dashboard/components/DashboardPieChartMock.vue";

@Component({
  components: { DashboardPieChartMock },
})
export default class DashboardMockSparkline extends Vue {
  @Prop() chart!: DashboardChartModel;
  @Prop() columns!: number;

  get title(): string {
    return `${this.chart.templateName} (${this.chart.name})`;
  }

  get isStacked(): boolean {
    return this.chart.type === DashboardChartType.STACKED;
  }

  get titleClass() {
    return new Map([
      [1, "text-h6"],
      [2, "subtitle-2"],
      [3, "caption"],
    ]).get(this.columns);
  }

  get height(): number {
    return this.chart.type === DashboardChartType.FUNNEL ? 40 : 80;
  }

  get padding(): number | undefined {
    return this.chart.type === DashboardChartType.FUNNEL ? 0 : undefined;
  }

  get isFunnelTemplate(): boolean {
    return [DashboardChartType.FUNNEL, DashboardChartType.COLUMN].includes(
      this.chart.type
    );
  }

  get isFunnelChart(): boolean {
    return this.chart.type === DashboardChartType.FUNNEL;
  }

  get isPieChart(): boolean {
    return this.chart.type === DashboardChartType.PIE;
  }

  get value(): Array<number> {
    return this.isFunnelTemplate
      ? Array.from(Array(5))
          .map(() => Math.floor(Math.random() * 100))
          .sort((valueA: number, valueB: number) => valueB - valueA)
      : Array.from(Array(15)).map(() => Math.floor(Math.random() * 10));
  }

  get type(): string {
    return this.isFunnelTemplate ? "bar" : "trend";
  }
}
