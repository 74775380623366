

















































































import { Component, Vue, Watch, Ref } from "vue-property-decorator";

import DashboardCreationForm from "@/dashboard/components/DashboardCreationForm.vue";
import DashboardTemplates from "@/dashboard/components/DashboardTemplates.vue";
import DashboardCharts from "@/dashboard/components/DashboardCharts.vue";
import DashboardModel, {
  DashboardType,
} from "@/dashboard/models/DashboardModel";
import { TemplateModel } from "@/templates/models/TemplateModel";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    DashboardCreationForm,
    DashboardTemplates,
    DashboardCharts,
  },
})
export default class DashboardItemView extends Vue {
  @Ref("form") readonly form!: DashboardCreationForm;

  stepValue = 1;
  selectedTemplates: Array<TemplateModel> = [];
  isFormValid = true;
  isDashboardUpdating = false;
  localDashboard: DashboardModel = new DashboardModel(
    1,
    this.dashboardType as DashboardType
  );
  dashboardName = "";
  isFavorite = false;

  get applicationId() {
    return this.$route.params.id;
  }

  get dashboard() {
    return this.$store.state.dashboardStore.dashboard;
  }

  get isNextStepDisabled() {
    return (
      this.isDashboardLoading ||
      !this.isFormValid ||
      (this.stepValue === 2 && !this.selectedTemplates.length) ||
      (this.stepValue === 3 && !this.localDashboard.charts.length)
    );
  }

  get dashboardId(): string {
    return this.$route.params.dashboardId;
  }

  get dashboardType(): string {
    return this.$route.params.type ?? DashboardType.MULTI_APP;
  }

  get isDashboardLoading(): boolean {
    return this.$store.state.dashboardStore.isDashboardLoading;
  }

  @Watch("dashboard", { immediate: true })
  watchDashboard() {
    if (!this.dashboard) {
      return;
    }

    this.isFavorite = this.dashboard.isDefault;
    this.localDashboard = DashboardModel.of(this.dashboard);
    this.dashboardName = this.dashboard.name;
  }

  beforeCreate() {
    this.$store.commit("clearDashboard");
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.dashboardId
        ? this.$lang("dashboard.editDashboardTitle")
        : this.$lang("dashboard.createTitle")
    );

    if (this.dashboardId) {
      this.$store.dispatch("getDashboard", this.dashboardId);
    }
  }

  goBack() {
    this.$router.push({
      name:
        this.dashboardId &&
        this.localDashboard.type === DashboardType.SINGLE_APP
          ? AppSection.SINGLEAPP_DASHBOARDS
          : AppSection.MULTIAPP_DASHBOARDS,
      params: {
        id: this.applicationId,
      },
    });
  }

  goNextStep() {
    if (this.stepValue === 1 && !this.form.validate()) {
      return;
    }

    this.stepValue += 1;
  }

  updateDashboard() {
    if (!this.form.validate()) {
      return;
    }

    this.isDashboardUpdating = true;

    this.$store
      .dispatch(
        this.dashboardId ? "updateDashboard" : "createDashboard",
        this.localDashboard
      )
      .then(() => {
        this.isDashboardUpdating = false;
        if (this.$route.query.templateId) {
          this.$router.push({
            name: "used_dashboards",
            params: { templateId: String(this.$route.query.templateId) },
          });
        } else {
          this.$router.push({
            name:
              this.localDashboard.type === DashboardType.MULTI_APP
                ? AppSection.MULTIAPP_DASHBOARDS
                : AppSection.SINGLEAPP_DASHBOARDS,
            params: { id: this.applicationId },
          });
        }
      });
  }
}
