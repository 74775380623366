






























































import { Component, Vue, Ref, Prop, Watch } from "vue-property-decorator";
import { debounce } from "lodash";

import DashboardModel, {
  DashboardType,
  DASHBOARD_TYPE_TO_APP_SECTION,
} from "@/dashboard/models/DashboardModel";
import { AccessType, CurrentUserModel } from "@/shared/models";
import { VueForm } from "@/shared/types/ExtendedVueType";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class DashboardCreationForm extends Vue {
  @Prop() dashboard!: DashboardModel;
  @Prop() isFavorite!: boolean;

  @Ref("form") readonly form!: VueForm;

  readonly nameRules = [ValidUtil.required(this.$lang("validation.required"))];
  readonly descriptionRules = [
    ValidUtil.maxLength(255, this.$lang("validation.maxLength", 255)),
  ];
  isValid = true;
  checkIsDashboardNameUnique = debounce(() => {
    this.$store.dispatch("checkIsDashboardNameUnique", this.dashboard);
  }, 500);

  get dashboardId(): string {
    return this.$route.params.dashboardId;
  }

  get isDashboardNameUnique(): boolean {
    return this.$store.state.dashboardStore.isDashboardNameUnique;
  }

  get nameErrorMessages(): Array<string> {
    return !this.isDashboardNameUnique
      ? [this.$lang("shared.errors.notUnique")]
      : [];
  }

  get accessTypes(): Array<{ text: string; value: AccessType }> {
    return Object.values(AccessType).map((type) => ({
      text: this.$lang(`accessType.${type.toString().toLowerCase()}`),
      value: type,
    }));
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get dashboardTypes(): Array<{ text: string; value: DashboardType }> {
    return Object.values(DashboardType).reduce(
      (result: Array<{ text: string; value: DashboardType }>, value) => {
        const appSection = DASHBOARD_TYPE_TO_APP_SECTION[value];

        if (
          this.currentUser.createAccessEntities[this.applicationId].includes(
            appSection
          )
        ) {
          result.push({
            text: this.$lang(
              `dashboard.type.${(value as string).toLowerCase()}`
            ),
            value,
          });
        }

        return result;
      },
      []
    );
  }

  @Watch("dashboardTypes")
  setDashboardType(types: Array<{ text: string; value: DashboardType }>) {
    if (types.some(({ value }) => value === this.dashboard.type)) {
      return;
    }

    this.dashboard.type = types[0].value;
  }

  @Watch("isValid", { immediate: true })
  watchValidation(value: boolean) {
    this.$emit("validate", value);
  }

  validate() {
    return this.form.validate();
  }
}
