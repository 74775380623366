













import { Component, Vue } from "vue-property-decorator";

@Component
export default class DashboardPieChartMock extends Vue {
  readonly paths = [
    {
      fill: "#67b7dc",
      path: "M31,26.962h26.924C56.94,12.541,45.421,1.022,31,0.038V26.962z",
    },
    {
      fill: "#6794dc",
      path: "M50.386,48.615c4.343-4.71,7.151-10.858,7.614-17.653H32.733L50.386,48.615z",
    },
    {
      fill: "#6771dc",
      path: "M27,28.134V0.038C11.918,1.067,0,13.619,0,28.962C0,36.25,2.695,42.905,7.134,48L27,28.134z",
    },
    {
      fill: "#8067dc",
      path: "M28.414,32.376L9.962,50.828c5.095,4.439,11.75,7.134,19.038,7.134c6.99,0,13.396-2.479,18.401-6.599L28.414,32.376z",
    },
  ];
}
