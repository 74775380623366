












































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import draggable from "vuedraggable";

import DashboardMockSparkline from "@/dashboard/components/DashboardMockSparkline.vue";
import DashboardModel from "@/dashboard/models/DashboardModel";
import { TemplateModel } from "@/templates/models/TemplateModel";
import {
  DashboardChart,
  DashboardChartModel,
  DashboardChartType,
} from "@/dashboard/models/DashboardChartModel";
import { Application } from "@/shared/models";

@Component({
  components: {
    draggable,
    DashboardMockSparkline,
  },
})
export default class DashboardCharts extends Vue {
  @Prop() dashboard!: DashboardModel;
  @Prop() selectedTemplates!: Array<TemplateModel>;

  chartSearch = "";

  get availableApps(): Array<Application> {
    return this.$store.state.application.apps;
  }

  get availableCharts(): Array<DashboardChartModel> {
    const search = this.chartSearch.toLowerCase();
    const charts = this.selectedTemplates.reduce(
      (result: Array<DashboardChartModel>, template: TemplateModel) => {
        return [
          ...result,
          ...template
            .getCharts(this.availableApps)
            .map((chart: DashboardChart) => {
              return new DashboardChartModel(
                chart.type,
                chart.name,
                false,
                template.id,
                template.name
              );
            }),
        ];
      },
      []
    );

    return charts.filter(
      (chart: DashboardChartModel) =>
        chart.name.toLowerCase().includes(search) ||
        chart.templateName.toLowerCase().includes(search)
    );
  }

  isChartSelected(chart: DashboardChartModel) {
    return this.dashboard.charts.some(
      (selectedChart) =>
        selectedChart.name === chart.name &&
        selectedChart.templateId === chart.templateId
    );
  }

  getLabel(chart: DashboardChartModel) {
    return [DashboardChartType.COLUMN, DashboardChartType.FUNNEL].includes(
      chart.type
    )
      ? this.$lang("funnel.funnel")
      : this.selectedTemplates.find(
          (template) => template.id === chart.templateId
        )?.label ?? "";
  }

  selectChart(chart: DashboardChartModel) {
    this.dashboard.charts.push(chart);
  }

  toggleExpandChart(chart: DashboardChartModel) {
    chart.expanded = !chart.expanded;
  }

  deleteChart(chart: DashboardChartModel) {
    chart.expanded = false;
    this.dashboard.charts = this.dashboard.charts.filter(
      (selectedChart) => selectedChart !== chart
    );
  }
}
