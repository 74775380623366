






















































import { Component, Vue, Prop } from "vue-property-decorator";

import { TemplateModel } from "@/templates/models/TemplateModel";

@Component
export default class DashboardAvailableTemplates extends Vue {
  @Prop() templateSearch!: string;
  @Prop() selectedTemplates!: Array<TemplateModel>;
  @Prop() templates!: Array<TemplateModel>;
  @Prop() isOnlyOwnTemplates!: boolean;

  get selectedTemplatesIds(): Array<number> {
    return this.selectedTemplates.map(({ id }) => id);
  }

  get availableTemplates() {
    const search = this.templateSearch.toLowerCase();
    const templates = this.isOnlyOwnTemplates
      ? this.templates.filter(
          (template: TemplateModel) =>
            template.ownerId ===
            this.$store.state.userStore.currentUser.username
        )
      : [...this.templates];

    return search
      ? templates.filter((template: TemplateModel) =>
          template.name.toLowerCase().includes(search)
        )
      : templates;
  }

  getAppNameByAppId(appId: string): string {
    return this.$store.getters.applicationsById.get(appId)?.name || appId;
  }
}
