










































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";

import DashboardAvailableTemplates from "@/dashboard/components/DashboardAvailableTemplates.vue";
import DashboardModel, {
  DashboardType,
} from "@/dashboard/models/DashboardModel";
import {
  FunnelTemplateModel,
  ReportTemplateModel,
  TemplateModel,
  TemplateType,
} from "@/templates/models/TemplateModel";
import { DashboardChartModel } from "@/dashboard/models/DashboardChartModel";
import { ReportType } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";

@Component({
  components: {
    DashboardAvailableTemplates,
  },
})
export default class DashboardTemplates extends Vue {
  @Prop() dashboard!: DashboardModel;
  @Prop() step!: number;
  @Prop() selectedTemplates!: Array<TemplateModel>;

  readonly TemplateType = TemplateType;
  isLoadingDashboard = false;
  templateSearch = "";
  checkedTemplateType = TemplateType.REPORT_TEMPLATE;
  isOnlyOwnTemplates = false;
  selectedReportTypes: Array<ReportType> = [];

  get dashboardId(): string {
    return this.$route.params.dashboardId;
  }

  get applicationId() {
    return this.$route.params.id;
  }

  get availableApps() {
    return this.$store.state.application.apps;
  }

  get reportTemplates(): Array<ReportTemplateModel> {
    return this.$store.state.templateStore.reportTemplates.filter(
      (template: ReportTemplateModel) => {
        return template.getCharts(this.availableApps).length;
      }
    );
  }

  get processedReportTemplates(): Array<ReportTemplateModel> {
    return this.selectedReportTypes.length
      ? this.reportTemplates.filter((template: ReportTemplateModel) =>
          this.selectedReportTypes.includes(template.report)
        )
      : this.reportTemplates;
  }

  get funnelTemplates(): Array<FunnelTemplateModel> {
    return this.$store.state.templateStore.funnelTemplates;
  }

  get availableTemplates(): Array<TemplateModel> {
    return [...this.reportTemplates, ...this.funnelTemplates];
  }

  get reportTypes(): Array<ReportType> {
    return this.reportTemplates.reduce(
      (result: Array<ReportType>, template: ReportTemplateModel) => {
        if (result.includes(template.report)) {
          return result;
        }

        return [...result, template.report];
      },
      []
    );
  }

  @Watch("step", { immediate: true })
  watchStepValue(currentValue: number, prevValue: number) {
    if (prevValue === 1) {
      this.fetchTemplates();
    }
  }

  @Watch("applicationId", { immediate: true })
  @Watch("availableApps")
  watchApplicationId() {
    if (!this.availableApps.length) {
      return;
    }

    this.fetchTemplates();
  }

  @Watch("selectedTemplates")
  watchSelectedTemplates() {
    if (!this.dashboard.charts.length) {
      return;
    }

    const selectedTemplatesIds = this.selectedTemplates.map(
      (template) => template.id
    );

    this.dashboard.charts = this.dashboard.charts.filter(
      (chart) =>
        chart.templateId && selectedTemplatesIds.includes(chart.templateId)
    );
  }

  fetchTemplates() {
    if (this.step === 1) {
      return;
    }

    const appId =
      this.dashboard.type === DashboardType.SINGLE_APP
        ? this.applicationId
        : undefined;

    this.isLoadingDashboard = true;

    Promise.all([
      this.$store.dispatch("fetchReportTemplates", {
        applicationId: appId,
        showSingleAppOnly: this.dashboard.type === DashboardType.SINGLE_APP,
      }),
      this.$store.dispatch("fetchFunnelTemplates", {
        applicationId: appId,
        showSingleAppOnly: this.dashboard.type === DashboardType.SINGLE_APP,
      }),
    ])
      .then(() => {
        if (this.dashboardId) {
          this.updateSelectedTemplates();
        } else {
          const availableTemplatesIds = this.availableTemplates.map(
            (template: TemplateModel) => template.id
          );

          this.$emit(
            "change",
            this.selectedTemplates.filter((template: TemplateModel) => {
              return availableTemplatesIds.includes(template.id);
            })
          );
        }
      })
      .finally(() => {
        this.isLoadingDashboard = false;
      });
  }

  updateSelectedTemplates() {
    const selectedTemplatesIds = this.dashboard.charts.map(
      (chart: DashboardChartModel) => chart.templateId
    );

    this.$emit(
      "change",
      this.availableTemplates.filter(
        (template: TemplateModel) =>
          template.id && selectedTemplatesIds.includes(template.id)
      )
    );
  }

  selectTemplate(template: TemplateModel) {
    this.$emit("change", [...this.selectedTemplates, template]);
  }

  deleteTemplate(template: TemplateModel) {
    this.$emit(
      "change",
      this.selectedTemplates.filter(
        (selectedTemplate) => selectedTemplate !== template
      )
    );
  }

  getReportName(reportType: ReportType) {
    return ReportUtil.getReportNameByReportType(reportType);
  }
}
